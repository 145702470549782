import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ApiHook } from "../../hooks/apiHook";
import { PhoneInput } from "react-international-phone";
import { NavLink, useLocation } from "react-router-dom";

const PartyHostGuestForm = (props) => {
    const location = useLocation();
    const { t } = useTranslation();
    const { register, watch, trigger, setValue, setError, reset, formState: { errors } } = useForm({
        name: props?.editData?.name ?? "",
        secondName: props?.editData?.secondName ?? "",
        email: props?.editData?.email ?? "",
        address: props?.editData?.address ?? "",
        phone: props?.editData?.phone ?? "",
        countryId: props?.editData?.country ?? "",
        stateId: props?.editData?.stateId ?? "",
        city: props?.editData?.city ?? "",
        zip: props?.editData?.zip ?? ""
    });
    const formValues = watch();
    const [country, setSelectedCountry] = useState(props?.editData?.country ?? null);
    const [selectedState, setSelectedState] = useState(props?.editData?.state ?? null);
    const [phoneNumber, setPhoneNumber] = useState(props?.editData?.phone ?? "");
    // phone validation
    const isPhoneValid = (phone) => {
        return phone.length >= 7 && !isNaN(phone);
    };
    const isPhoneNumberValid = isPhoneValid(phoneNumber);
    // api
    const countries = ApiHook.CallGetCountries();
    const states = ApiHook.CallGetStates();
    const stateList = () => {
        if (!formValues?.countryId) {
            return []; // Return an empty array if there's no country specified.
        }

        const selectedCountry = formValues.countryId

        if (!selectedCountry) {
            return []; // Return an empty array if the selected country is not found.
        }

        return states?.data?.filter(state => state.countryId === selectedCountry)
    };

    const countryChange = (country) => {
        setSelectedCountry(country);
        setValue("countryId", country?.value);
        setError("countryId", { message: "" });
        setValue("stateId", null);
        setSelectedState(null);
    };

    const changeState = (state) => {
        setSelectedState(state?.value);
        setValue("stateId", state?.value);
    };

    const handlePhoneNumber = (phone) => {
        setPhoneNumber(phone);
        setValue("phone", phone);
        setError("phone", { message: "" });
    };
    const clearForm = () => {
        reset();
        setPhoneNumber("");
        setSelectedCountry(null);
        setSelectedState(null);
    }
    // submit function
    const addUser = async (e) => {
        e.preventDefault();
        let isValid = await trigger();
        if (!isPhoneNumberValid) {
            isValid = false;
            setError("phone", {
                message: ("min_length"),
            });
        }
        let payload = {};
        if (props.editData) {
            payload = {
                ...formValues,
                id: props.editData.id
            }
        } else {
            payload = formValues
        }
        if (isValid && payload) {
            await props.submitFunction(payload,clearForm);
        }
    }

    useEffect(() => {
        if (props?.editData?.country) {
            setValue("countryId", props?.editData?.country.value);
        }
        if (props?.editData?.state) {
            setValue("stateId", props?.editData?.state.value);
        }
        if (props?.editData?.phone) {
            setValue("phone", props?.editData?.phone);
        }
    }, [props.editData])

    return (
        <div className="frm-content">
            <div className="p-3 container-fluid">
                <div className="crm_panel__h6izZ ">
                    <div className="crm_panel_body__31wq1">
                        <form onSubmit={addUser}>
                            <div className="row">

                                <div className="col-xl-4">
                                <div className="form-group">
                                        <label className="Common_required__2JC55 form-label">{t('name')}*</label>
                                        <input
                                            {...register("name", {
                                                required: {
                                                    value: true,
                                                    message: (
                                                        "this_field_is_required"
                                                    ),
                                                },
                                            })}
                                            id="name"
                                            name="name"
                                            placeholder={t('name')}
                                            type="text"
                                            className="form-control"
                                            onBlur={async () =>
                                                await trigger("name")
                                            }
                                            defaultValue={props?.editData?.name}
                                        />
                                        <span className="error-message-validator">
                                            {t(errors?.name?.message)}
                                        </span>
                                    </div>
                                </div>

                                <div className="col-xl-4">
                                 <div className="form-group">
                                        <label className="form-label">{t('lastName')}*</label>
                                        <input
                                            {...register("secondName", {
                                                required: {
                                                    value: true,
                                                    message: (
                                                        "this_field_is_required"
                                                    ),
                                                },
                                            })}
                                            id="secondName"
                                            name="secondName"
                                            placeholder={t('lastName')}
                                            type="text"
                                            className="form-control"
                                            onBlur={async () =>
                                                await trigger("secondName")
                                            }
                                            defaultValue={props?.editData?.secondName}
                                        />
                                        <span className="error-message-validator">
                                            {t(errors?.secondName?.message)}
                                        </span>
                                    </div>
                                 </div>

                                 <div className="col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label">{t('email')}*</label>
                                        <input
                                            {...register("email", {
                                                required: {
                                                    value: true,
                                                    message: (
                                                        "this_field_is_required"
                                                    ),
                                                },
                                                pattern: {
                                                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                                    message: t(
                                                        "invalid_email_format"
                                                    ),
                                                },
                                            })}
                                            id="email"
                                            name="email"
                                            type="text"
                                            placeholder={t("emailId")}
                                            className="form-control"
                                            onBlur={async () =>
                                                await trigger("email")
                                            }
                                            defaultValue={props?.editData?.email}
                                        />
                                        <span className="error-message-validator">
                                            {t(errors?.email?.message)}
                                        </span>
                                    </div>
                                </div>

                                <div className="col-xl-4">
                                <div className="form-group">
                                        <label className="form-label">{t('mobile')}*</label>
                                        <PhoneInput
                                            className="phone-input"
                                            defaultCountry="us"
                                            value={phoneNumber}
                                            onChange={handlePhoneNumber}
                                        />
                                        <span className="error-message-validator">
                                            {t(errors?.phone?.message)}
                                        </span>
                                    </div>
                                 </div>

                                 <div className="col-xl-4">
                                    <div className="form-group">
                                        <label className="form-label">{t('country')}</label>
                                        <Select
                                            id={"countryId"}
                                            name={"countryId"}
                                            className={`dropdown-common-addLead ${errors["countryId"]
                                                ? "error-field"
                                                : ""
                                                }`}
                                            {...register("countryId", {
                                                // required: {
                                                //     value: true,
                                                //     message: (
                                                //         "this_field_is_required"
                                                //     )
                                                // }
                                            })}
                                            onBlur={async () =>
                                                await trigger("countryId")
                                            }
                                            options={countries?.data}
                                            onChange={countryChange}
                                            defaultValue={props?.editData?.country}
                                            value={country}
                                            isSearchable
                                        />
                                        <span className="error-message-validator">
                                            {t(errors?.countryId?.message)}
                                        </span>
                                    </div>
                                </div>

                                 <div className="col-xl-4">
                                 <div className="form-group">
                                        <label className="form-label">{t('state')}</label>
                                        <Select
                                            isSearchable
                                            id="stateId"
                                            name="stateId"
                                            className={`dropdown-common-addLead ${errors["stateId"] ? "error-field" : ""
                                                }`}
                                            {...register("stateId", {
                                                // required: {
                                                //     value: false,
                                                //     message: t("this_field_is_required"),
                                                // },
                                            })}
                                            options={country ? stateList(states?.data) : []}
                                            defaultValue={props?.editData?.state}
                                            value={selectedState ? stateList(countries)?.find(
                                                (state) => state?.value === (selectedState?.value ?? selectedState)
                                            ) : ""}
                                            onChange={changeState}
                                            onBlur={async () => await trigger("stateId")}
                                        />
                                        <span className="error-message-validator">
                                            {errors?.stateId?.message}
                                        </span>
                                    </div>
                                 </div>

                                 <div className="col-xl-4">
                                 <div className="form-group">
                                        <label className="Common_required__2JC55 form-label">{t('city')}</label>
                                        <div>
                                            <div className="Container-sc-crzztr-0 furIyG">
                                                <input
                                                    {...register("city", {
                                                        // required: {
                                                        //     value: true,
                                                        //     message: t("this_field_is_required"
                                                        //     )
                                                        // }
                                                    })}
                                                    name="city"
                                                    id="city"
                                                    className="form-control Common_datePicker__1cKv2 Input__DInput-sc-12thhga-0 lmpcCT"
                                                    placeholder={t("city")}
                                                    type="text"
                                                    defaultValue={props?.editData?.city}
                                                    onBlur={async () =>
                                                        await trigger("city")
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <span className="error-message-validator">
                                            {errors?.city?.message}
                                        </span>
                                    </div>
                                 </div>

                                <div className="col-xl-4">
                                    <div className="form-group">
                                        <label className="Common_required__2JC55 form-label">{t('zipCode')}*</label>
                                        <div>
                                            <div className="Container-sc-crzztr-0 furIyG">
                                                <input {...register("zip", {
                                                    required: {
                                                        value: true, message: t("this_field_is_required")
                                                    },
                                                })}
                                                    id="zip"
                                                    className="form-control Common_datePicker__1cKv2 Input__DInput-sc-12thhga-0 lmpcCT"
                                                    placeholder="Zip Code"
                                                    type="text"
                                                    defaultValue={props?.editData?.zip}
                                                    onBlur={async () =>
                                                        await trigger("zip")
                                                    } />
                                            </div>
                                        </div>
                                        <small className="d-none form-text">&nbsp;</small>
                                        <span className="error-message-validator">
                                            {errors?.zip?.message}
                                        </span>
                                    </div>
                                </div>
                                <div className=" col-xl-12 col-md-12">
                                <div className="form-group">
                                        <label className="form-label">{t('address')}*</label>
                                        <textarea
                                            {...register("address", {
                                                required: {
                                                    value: true,
                                                    message: (
                                                        "this_field_is_required"
                                                    ),
                                                },
                                            })}
                                            id="address"
                                            name="address"
                                            placeholder={t('address')}
                                            type="text"
                                            className="form-control"
                                            onBlur={async () =>
                                                await trigger("address")
                                            }
                                            defaultValue={props?.editData?.address}
                                        />
                                        <span className="error-message-validator">
                                            {t(errors?.address?.message)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex gap-4">
                                <NavLink to={props?.backBtnPath} state={{ selectedParty: props?.selectedParty }}>
                                    <button type="button" className="btn btn-dark text-white float-start back rounded-3 px-3">{t('back')}</button>
                                </NavLink>
                                <button type="submit" className="Common_customBtn__2_PSp Common_primary__2pdY1  btn btn-primary">{t(props.type)}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PartyHostGuestForm;