import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const GuestManagement = (props) => {
  const { t } = useTranslation();
  return (
    <div className="row row_top justify-content-center form-business">
      <div className="row">
        <div className="col-md-9">
          <div className="regsiter_step_1_view_left_sec_head mb-0">{t('Step Two: Add guests and manage invites')}</div>
        </div>
        {props?.isClosed &&
          <>
            <div className="col-md-3 d-flex justify-content-end mt-sm-0 mt-2">
              <span>
                <Link to='/guest-management' className="btn mt-2 rounded-2 setup-Party text-white" state={{ 'selectedParty': props.selectedParty }} >{t('addguest')}</Link>

              </span>
              <span style={{ paddingLeft: "5px" }}>

                <Link to='/invite-guest' className="btn mt-2 rounded-2 setup-Party text-white" state={{ 'selectedParty': props.selectedParty }}>{t('inviteGuest')}</Link>
              </span>
            </div>
          </>
        }

      </div>
      <div className="table-responsive">
        <table className="table table-manage-invite-party-portal mb-5 border-table">
          <thead>
            <tr>
              <th>#</th>
              <th>{t('Guest Name')}</th>
              <th>{t('Guest Email')}</th>
              <th>{t('Guest Phone')}</th>
            </tr>
          </thead>
          <tbody>
            {props.guests ?
              (props.guests?.map((guest, index) => (
                <tr key={guest.id}>
                  <td>{index + 1}</td>
                  <td>{guest.name}</td>
                  <td>{guest.email}</td>
                  <td>{guest.phone}</td>
                </tr>
              ))) : (
                <tr>
                  <td colSpan="5">{t('No data found...')}</td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
      <div className="col-md-12">
        <button type="button" className="btn btn-dark text-white float-start back mt-4 rounded-3" onClick={props.onBack}>{t('back')}</button>
        <button type="button" className="btn text-white float-end next mt-4 rounded-3 bg-color-info" onClick={props.onNext}>{t('next')}</button>
      </div>
    </div>
  );
};

export default GuestManagement;
